<template>
  <div class="wrapper">
    <div class="job-tag-select">招聘会</div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" disabled>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        :immediate-check="false"
      >
        <div class="job-item-wrapper" v-for="(item, i) in list" :key="i">
          <div class="item-box">
            <fair-item :data="item" :i="i" />
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import FairItem from "@/components/FairItem";
import { getfairsList } from "@/api/jobfairs";
export default {
  name: "",
  components: { FairItem },
  data() {
    return {
      list: [],
      loading: false,
      noMore: false,
      finished: false,
      refreshing: false,
      pageSize: 10,
      pageNum: 1,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.search();
  },
  methods: {
    selectChange(item) {
      this.pageNum = 1;
      this.initPageData();
      this.search();
    },
    initPageData() {
      this.pageNum = 1;
      this.loading = false;
      this.noData = false;
      this.noMore = false;
    },
    onLoad() {
      this.pageNum++;
      this.search();
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.pageNum = 1;
      this.search();
    },
    //获取培训列表
    search() {
      this.loading = true;
      const { pageNum, pageSize } = this;
      const params = {
        pageNum,
        pageSize,
      };
      const P = {
        apiName: "/recruit/jobfairs/list",
        paramMap: params,
        requestType: 1,
      };
      getfairsList(P)
        .then((res) => {
          if (!res) {
            this.finished = true;
            this.noData = true;
            return;
          }
          if (this.pageNum === 1) {
            this.list = res.data.records;
          } else {
            this.list = [...this.list, ...res.data.records];
          }
          this.loading = false;
          this.refreshing = false;
          //判断全部加载完毕
          if (res.data.total <= this.list.length) {
            this.finished = true;
          } else {
            this.finished = false;
          }
        })
        .catch((err) => {
          this.finished = true;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  height: 100vh;
  background: #fff;
  .title {
    width: 100%;
    background: #fff;
    color: #000;
  }
}

.job-item-wrapper {
  box-sizing: border-box;
  padding: 0.1rem 0.2rem;
  // background: #f0f5f7;
  .item-box {
    padding: 0.1rem 0.1rem 0.1rem 0.3rem;
    background: #fff;
  }
}
.job-tag-select {
  padding: 0.3rem 0.3rem 0.1rem 0.3rem;
  align-items: center;
  width: 100%;
  font-size: 0.5rem;
}
</style>
